export default {
  HOME: "/",
  AUTH: "/auth",
  COMPANY: "/company",
  COMPANY_DETAIL: "/company/:companyId",
  ACCOUNT: "/account",
  ORDER_IMPORT: "/order/import",
  DLQ: "/dlq",
  DLQ_DETAIL: "/dlq/:messageId",
  PAGE_NOT_FOUND: "/404",
  BACKOFFICE_USER: "/backoffice-user",
  BACKOFFICE_USER_CREATE: "/backoffice-user/create",
  BACKOFFICE_USER_UPDATE: "/backoffice-user/:username",
  HISTORIZATION: "/historization",
  DELETE_COMPANY: "/delete-company",
  SELLWERK_TRUSTED: "/sellwerk-trusted",
  RECOMMENDATIONS_DETAILS: "/sellwerk-trusted/recommendations-details",
  INVOICES: "/invoices",
  GROUPS: "/groups",
}
