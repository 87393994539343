export const allow = {
  all: "all",
  resend_onboarding_email: "resend_onboarding_email",
  change_email: "change_email",
  activate_deactivate_user: "activate_deactivate_user",
  change_email_verified: "change_email_verified",
  change_personal_data: "change_personal_data",
  change_default_company: "change_default_company",
  activate_account: "activate_account",
  deactivate_account: "deactivate_account",
  delete_account: "delete_account",
}

export const areas = {
  dlq: "DLQ",
  account: "Account",
  company: "Company",
  orderImport: "OrderImport",
  backofficeUser: "BackofficeUser",
  historization: "Historization",
  deleteCompany: "DeleteCompany",
  sellwerkTrusted: "SellwerkTrusted",
  invoices: "Invoices",
  groups: "Groups",
}

export const privileges = [
  {
    role: "admin",
    access: [
      {
        area: areas.dlq,
        allow: [allow.all],
      },
      {
        area: areas.account,
        allow: [allow.all],
      },
      {
        area: areas.company,
        allow: [allow.all],
      },
      {
        area: areas.orderImport,
        allow: [allow.all],
      },
      {
        area: areas.backofficeUser,
        allow: [allow.all],
      },
      {
        area: areas.historization,
        allow: [allow.all],
      },
      {
        area: areas.deleteCompany,
        allow: [allow.all],
      },
      {
        area: areas.sellwerkTrusted,
        allow: [allow.all],
      },
      {
        area: areas.invoices,
        allow: [allow.all],
      },
    ],
  },
  {
    role: "developer",
    access: [
      {
        area: areas.dlq,
        allow: [allow.all],
      },
      {
        area: areas.historization,
        allow: [allow.all],
      },
      {
        area: areas.invoices,
        allow: [allow.all],
      },
    ],
  },
  {
    role: "sdesupport",
    access: [
      {
        area: areas.account,
        allow: [
          allow.resend_onboarding_email,
          allow.change_email,
          allow.activate_deactivate_user,
          allow.change_email_verified,
          allow.change_personal_data,
          allow.change_default_company,
        ],
      },
      {
        area: areas.company,
        allow: [allow.all],
      },
      {
        area: areas.orderImport,
        allow: [allow.all],
      },
      {
        area: areas.historization,
        allow: [allow.all],
      },
      {
        area: areas.sellwerkTrusted,
        allow: [allow.all],
      },
      {
        area: areas.invoices,
        allow: [allow.all],
      },
    ],
  },
  {
    role: "customersupport",
    access: [
      {
        area: areas.account,
        allow: [allow.resend_onboarding_email],
      },
      {
        area: areas.invoices,
        allow: [allow.all],
      },
    ],
  },
  {
    role: "editor",
    access: [
      {
        area: areas.account,
        allow: [allow.resend_onboarding_email, allow.change_email, allow.activate_deactivate_user, allow.change_email_verified, allow.change_personal_data],
      },
      {
        area: areas.company,
        allow: [allow.all],
      },
      {
        area: areas.historization,
        allow: [allow.all],
      },
      {
        area: areas.invoices,
        allow: [allow.all],
      },
    ],
  },
  {
    role: "communitymoderator",
    access: [
      {
        area: areas.groups,
        allow: [allow.all],
      },
    ],
  },
]
